// Styles
import '@mdi/font/css/materialdesignicons.css'

import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  icons: {
    defaultSet: 'mdi', // This is already the default value - only for display purposes
  },
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme: {
        dark    : false,
        colors  : {
          background          : '#FFFFFF',
          primary             : '#424242',
          secondary           : '#5e92f9',
          accent1             : '#5ecaff',
          accent2             : '#B6C8A9',
          accent3             : '#6d2500',
        }
      }
    }
  }
})
