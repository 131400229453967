import { useRouter, createRouter, createWebHistory }  from 'vue-router'
import { defaultStore }                               from '@/stores/defaultStore'
import { navStore }                                   from '@/stores/navStore'

import defaultComponent from '../components/appComponents/Default.vue'


const routes  = [
  // PUBLIC PAGES
  {
    path: '/',
    component: () => import('../components/Home.vue')
  },
  {
    path: '/Login',
    component: () => import('../components/Login.vue')
  },
  {
    path: '/Matches',
    component: () => import('../components/Matches.vue')
  },
  {
    path: '/Matches/Match/:matchId',
    component: () => import('../components/matchComponents/Match.vue'),
    props: route => ({matchId: route.params.matchId})
  },
  {
    path: '/Registration',
    component: defaultComponent,
  },
  {
    path: '/Registration/FreeAgent',
    component: () => import('../components/regComponents/playerRegistration.vue')
  },
  {
    path: '/Registration/Team',
    component: () => import('../components/regComponents/teamRegistration.vue')
  },
  {
    path: '/Reports',
    component: defaultComponent,
  },
  {
    path: '/Reports/MissingVods',
    component: () => import('../components/reports/missingVods.vue')
  },
  {
    path: '/Reports/AllRosters',
    component: () => import('../components/reports/allRosters.vue')
  },
  {
    path: '/Participants',
    component: defaultComponent
  },
  {
    path: '/Participants/AllPlayers',
    component: () => import('../components/participantComponents/AllPlayers.vue')
  },
  {
    path: '/Participants/AllTeams',
    component: () => import('../components/participantComponents/AllTeams.vue')
  },
  {
    path: '/Participants/FreeAgents',
    component: () => import('../components/participantComponents/FreeAgents.vue')
  },
  {
    path: '/Participants/Player/:playerId',
    component: () => import('../components/participantComponents/Player.vue'),
    props: route => ({playerId: route.params.playerId})
  },
  {
    path: '/Participants/Team/:teamId',
    component: () => import('../components/participantComponents/Team.vue'),
    props: route => ({teamId: route.params.teamId})
  },
  // ADMIN PAGES
  {
    path: '/Admin',
    component: defaultComponent,
  },
  {
    path: '/Admin/Logs',
    component: () => import('../components/adminComponents/Logs.vue'),
  },
  {
    path: '/Admin/Matches',
    component: defaultComponent
  },
  {
    path: '/Admin/Participants',
    component: defaultComponent
  },
  {
    path: '/Admin/Matches/AddMatch',
    component: () => import('../components/adminComponents/addMatch.vue'),
  },
  {
    path: '/Admin/Matches/EditMatch/:matchId',
    component: () => import('../components/adminComponents/editMatch.vue'),
    props: route => ({matchId: route.params.matchId})
  },
  {
    path: '/Admin/Matches/ManageMatches',
    component: () => import('../components/adminComponents/manageMatches.vue'),
  },
  {
    path: '/Admin/Participants/AddTeam',
    component: () => import('../components/adminComponents/addTeam.vue'),
  },
  {
    path: '/Admin/Participants/EditTeam/:teamId',
    component: () => import('../components/adminComponents/editTeam.vue'),
    props: route => ({teamId: route.params.teamId})
  },
  {
    path: '/Admin/Participants/ManageTeams',
    component: () => import('../components/adminComponents/manageTeams.vue'),
  },
  {
    path: '/Admin/Participants/AddPlayer',
    component: () => import('../components/adminComponents/addPlayer.vue'),
  },
  {
    path: '/Admin/Participants/EditPlayer/:playerId',
    component: () => import('../components/adminComponents/editPlayer.vue'),
    props: route => ({playerId: route.params.playerId})
  },
  {
    path: '/Admin/Participants/ManagePlayers',
    component: () => import('../components/adminComponents/managePlayers.vue'),
  },
  {
    path: '/Admin/Settings',
    component: defaultComponent
  },
  {
    path: '/Admin/Settings/FrontPage',
    component: () => import('../components/adminComponents/settingsFrontPage.vue'),
  },
  {
    path: '/Admin/Settings/League',
    component: () => import('../components/adminComponents/settingsLeague.vue'),
  },
  {
    path: '/Admin/Settings/Site',
    component: () => import('../components/adminComponents/settingsSite.vue'),
  },
  {
    path: '/Admin/Users',
    component: defaultComponent
  },
  {
    path: '/Admin/Users/AddUser',
    component: () => import('../components/adminComponents/editUser.vue'),
  },
  {
    path: '/Admin/Users/ManageUsers',
    component: () => import('../components/adminComponents/manageUsers.vue'),
  },
  // 404
  {
    path: '/:pathMatch(.*)*',
    component : () => import('../components/404.vue')
  }
]

var vrouter = createRouter({
  history : createWebHistory(),
  routes
})

vrouter.beforeEach(async (to, from) => {
  var nav       = navStore()
  var navItems  = nav.getCombinedNav
  var store     = defaultStore()
  var userData  = store.user
  
  //IF THE PAGE IS NOT AN ADMIN PAGE, GO FORWARD
  if (!to.fullPath.startsWith("/Admin")){
    return true
  }
  else {
    var userAccess  = parseInt(userData.role.slice(0,1))
    var linkAccess  = navItems.filter(item => {
      if (to.fullPath.startsWith(item.link) && item.parent != ""){
        return item
      }
    })
    // console.log(userAccess)
    // console.log(linkAccess)
    if (userAccess >= linkAccess[0].access){
      return true
    }
    else {
      return { path: '/Login' }
    }
  }
})

export default vrouter
