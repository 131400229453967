<template>
    <v-snackbar
      v-model="notification.status"
      timeout="5000"
      app
      top
      right
      color="black"
      multi-line
    >
      <div class="d-flex text-center text-body-1">
        {{ notification.msg }}
      </div>
    </v-snackbar>
</template>

<script setup>
// VUE METHODS
import { ref, computed }      from 'vue'

// COMPONENTS

// STORES
import { defaultStore } from '@/stores/defaultStore'
const store     = defaultStore()

// DATA

//METHODS

//COMPUTED
const notification  = computed(() => store.dialog)

</script>