<template>
   <v-app-bar color="primary" v-if="settings == undefined"></v-app-bar>
   <v-app-bar location="bottom" color="primary" density="compact" v-else>
      <template v-slot:append>
         <v-btn v-if="settings.discord.active" :href="settings.discord.link" target="_blank">
            <v-icon start>mdi-discord</v-icon>
            <span class="hidden-sm-and-down">Discord</span>
         </v-btn>
         <v-btn v-if="settings.dotabuff.active" :href="settings.dotabuff.link" target="_blank">
            <v-icon start>mdi-format-list-text</v-icon>
            <span class="hidden-sm-and-down">Dotabuff</span>
         </v-btn>
         <v-btn v-if="settings.twitter.active" :href="settings.twitter.link" target="_blank">
            <v-icon start>mdi-twitter</v-icon>
            <span class="hidden-sm-and-down">Twitter</span>
         </v-btn>
      </template>
   </v-app-bar>
</template>

<script setup>
import { computed }     from 'vue'
import { defaultStore } from '@/stores/defaultStore'
const store       = defaultStore()
const settings    = computed(() => store.settings[0])
</script>
