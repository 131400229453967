<template>
    <v-container fluid>
        <v-row v-if="subComponentData == undefined" justify="center" align="center">
            <v-col cols="12" >
                Loading...
            </v-col>
        </v-row>
        <v-row v-else justify="space-around">
            <template v-for="item in subComponentData.items" :key="item">
                <v-card 
                    v-if="checkPerms(item)"
                    height="125" width="125"
                    color="secondary"
                    class="d-flex justify-center align-center ma-2 text-uppercase text-center"
                    hover
                    :to="item.parent + item.link"
                >
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-icon :icon="item.icon" v-if="item.icon"></v-icon>
                        </v-col>
                        <v-col cols="12">
                            {{item.name}}
                        </v-col>
                    </v-row>
                </v-card>
            </template>
        </v-row>
    </v-container>
</template>

<script setup>
import { computed }             from 'vue'
import { defaultStore }         from '@/stores/defaultStore'
import { navStore }             from '@/stores/navStore'
import { useRoute, useRouter }  from 'vue-router'

const route     = useRoute()
const router    = useRouter()
const nav       = navStore()
const store     = defaultStore() 
const userData  = computed(() => store.user)

//METHODS
function checkPerms(item){
    if (item.access == null || item.access == undefined){
        return true
    }
    else {
        let userPerm = parseInt(userData.value.role.slice(0,1))
        if (userPerm >= item.access){
            return true
        }
        else {
            return false
        }
    }
}

//COMPUTED
const fullNav           = computed(() => nav.getCombinedNav)
const subNav            = computed(() => nav.subNav)
const selected          = computed(() => route.path)
const subComponentData  = computed(() => {
    var subData   = {
        items : subNav.value.filter(item => item.parent == selected.value)
    } 
    return subData
})


</script>