import { defineStore }  from "pinia"

export const navStore = defineStore('nav', {
    state:() => {
        return{
            adminNav: [
                {
                    access  : 1,
                    icon    : "mdi-calendar-week",
                    link    : "/Admin/Matches",
                    name    : "Manage Matches"
                },
                {
                    access  : 2,
                    icon    : "mdi-account-group",
                    link    : "/Admin/Participants",
                    name    : "Mange Participants"
                },
                {
                    access  : 4,
                    icon    : "mdi-account-cog",
                    link    : "/Admin/Users",
                    name    : "Manage Users"
                },
                {
                    access  : 4,
                    icon    : "mdi-cogs",
                    link    : "/Admin/Settings",
                    name    : "Manage Settings"
                },
                {
                    access  : 4,
                    icon    : "mdi-view-list",
                    link    : "/Admin/Logs",
                    name    : "Logs"
                },
            ],
            baseNav: [
                {
                    access  : null,
                    icon    : "mdi-home",
                    link    : "/",
                    name    : "Home"
                },
                {
                    access  : null,
                    icon    : "mdi-text-box",
                    link    : "/Rules",
                    name    : "Rules"
                },
                {
                    access  : null,
                    icon    : "mdi-playlist-check",
                    link    : "/Registration",
                    name    : "Register"
                },
                {
                    access  : null,
                    icon    : "mdi-account-group",
                    link    : "/Participants",
                    name    : "Participants"
                },
                {
                    access  : null,
                    icon    : "mdi-calendar-week",
                    link    : "/Matches",
                    name    : "Matches"
                },
                {
                    access  : null,
                    icon    : "mdi-chart-bar",
                    link    : "/Reports",
                    name    : "Reports"
                },
            ],
            subNav : [
                // ADMIN
                {
                    access  : 1,
                    icon    : "mdi-calendar-week",
                    link    : "/Matches",
                    name    : "Manage Matches",
                    parent  : "/Admin"
                },
                {
                    access  : 4,
                    icon    : "mdi-view-list",
                    link    : "/Logs",
                    name    : "View Logs",
                    parent  : "/Admin"
                },
                {
                    access  : 1,
                    icon    : "mdi-plus",
                    link    : "/AddMatch",
                    name    : "Add Match",
                    parent  : "/Admin/Matches"
                },
                {
                    access  : 1,
                    icon    : "mdi-pencil",
                    link    : "/ManageMatches",
                    name    : "All Matches",
                    parent  : "/Admin/Matches",
                },
                {
                    access  : 2,
                    icon    : "mdi-account-group",
                    link    : "/Participants",
                    name    : "Manage Participants",
                    parent  : "/Admin"
                },
                {
                    access  : 3,
                    icon    : "mdi-account-plus",
                    link    : "/AddPlayer",
                    name    : "Add Player",
                    parent  : "/Admin/Participants",
                },
                {
                    access  : 3,
                    icon    : "mdi-account-group",
                    link    : "/AddTeam",
                    name    : "Add Team",
                    parent  : "/Admin/Participants"
                },
                {
                    access  : 2,
                    icon    : "mdi-account-edit-outline",
                    link    : "/ManagePlayers",
                    name    : "Manage Players",
                    parent  : "/Admin/Participants",
                },
                {
                    access  : 2,
                    icon    : "mdi-account-group",
                    link    : "/ManageTeams",
                    name    : "Manage Teams",
                    parent  : "/Admin/Participants"
                },
                {
                    access  : 4,
                    icon    : "mdi-account-cog",
                    link    : "/Users",
                    name    : "Manage Users",
                    parent  : "/Admin"
                },
                {
                    access  : 4,
                    icon    : "mdi-account-plus",
                    link    : "/AddUser",
                    name    : "Add Site User",
                    parent  : "/Admin/Users"
                },
                {
                    access  : 4,
                    icon    : "mdi-account-edit",
                    link    : "/ManageUsers",
                    parent  : "/Admin/Users",
                    name    : "Manage Site Users"
                },
                {
                    access  : 4,
                    icon    : "mdi-cogs",
                    link    : "/Settings",
                    name    : "Manage Settings",
                    parent  : "/Admin"
                },
                {
                    access  : 4,
                    icon    : "mdi-cog",
                    link    : "/League",
                    name    : "League Settings",
                    parent  : "/Admin/Settings"
                },
                {
                    access  : 4,
                    icon    : "mdi-application-cog",
                    link    : "/Site",
                    name    : "Site Settings",
                    parent  : "/Admin/Settings",
                },
                {
                    access  : 4,
                    icon    : "mdi-file-cog",
                    link    : "/FrontPage",
                    name    : "Front Page Content",
                    parent  : "/Admin/Settings",
                },
                //BASE
                {
                    access  : null,
                    icon    : "mdi-account-multiple",
                    link    : "/AllTeams",
                    name    : "Teams",
                    parent  : "/Participants",
                },
                {
                    access  : null,
                    icon    : "mdi-card-account-details-outline",
                    link    : "/AllPlayers",
                    name    : "All Players",
                    parent  : "/Participants",
                },
                {
                    access  : null,
                    icon    : "mdi-account-plus",
                    link    : "/FreeAgents",
                    name    : "Free Agents",
                    parent  : "/Participants"
                },
                {
                    access  : null,
                    icon    : "mdi-account-plus",
                    link    : "/FreeAgent",
                    name    : "Register as a Free Agent",
                    parent  : "/Registration",
                },
                {
                    access  : null,
                    icon    : "mdi-login-variant",
                    link    : "/Login",
                    name    : "Login",
                    parent  : ""
                },
                {
                    access  : null,
                    icon    : "mdi-account-multiple-plus",
                    link    : "/Team",
                    name    : "Register as a Team",
                    parent  : "/Registration"
                },
                {
                    access  : null,
                    icon    : "mdi-account-multiple",
                    link    : "/AllRosters",
                    name    : "All Rosters",
                    parent  : "/Reports"
                },
                {
                    access  : null,
                    icon    : "mdi-monitor-account",
                    link    : "/MissingVods",
                    name    : "Matches Missing a VOD",
                    parent  : "/Reports"
                },
            ],
        }
    },
    getters: {
        getCombinedNav(){
            let allNav = this.baseNav.concat(this.adminNav).concat(this.subNav)
            return allNav.map(item => {
                return {
                    access  : item.access == undefined ? null       : item.access,
                    icon    : item.icon,
                    name    : item.name,
                    parent  : item.parent == undefined ? ""         : item.parent,
                    link    : item.parent == undefined ? item.link  : item.parent + item.link
                }
            })
        }
    }
})