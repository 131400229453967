import { defineStore }      from "pinia"

// FILES
import defaultActions   from "./defaultStoreActions"

export const defaultStore = defineStore('default', {
    state:() => {
        return{
            accessLevels: [
                {
                    level: 0,
                    title: "Inactive"
                },
                {
                    level: 1,
                    title: "Caster/Reporter"
                },
                {
                    level: 2,
                    title: "Captain"
                },
                {
                    level: 3,
                    title: "Admin"
                },
                {
                    level: 4,
                    title: "Super Admin"
                }
            ],
            allUsers: [],
            dialog: {
                msg     : "test",
                status  : false
            },
            groups: [],
            loading: {
                msg     : "Loading...",
                status  : true
            },
            logs    : [],
            matches : [],
            notification: {
                msg     : "",
                status  : false
            },
            players     : [],
            settings    : [],
            teams       : [],
            user        : null
        }
    },
    actions: defaultActions,
    getters: {
        getRoles(){
            return this.accessLevels.map(role => {
                return `${role.level} - ${role.title}`
            })
        },
        getRounds(){
            let roundNum = this.settings[0].rounds
            let iterated = []
            for(let i=1; i <= (roundNum); i++){
                iterated.push(i)
            }
            return iterated
        },
        async getSettings(){
            await this.settings[0]
        },
        getTeams(){
            return this.teams
        },
        async getUserLocalState(){
            let localUser   = JSON.parse(localStorage.getItem("userData"))
            let userData    = await this.allUsers.filter(user => user.email == localUser.email)[0]
            return userData
        }
    }
})
