<template>
  <v-app>
    <appHeader @showNav="showNav = !showNav"></appHeader>
    <!-- FOOTER NEEDS TO BE LOCATED HERE FOR THE LAYOUT TO BE CORRECT -->
    <appFooter></appFooter>
    <!-- PRIMARY NAVIGATION -->
    <v-navigation-drawer v-model="showNav">
      <appNav @goToSub="goToSub"></appNav>
    </v-navigation-drawer>
    <!-- MAIN -->
    <v-main class="fill-height" app>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="10" offset-md="1">
            <v-card variant="outlined" color="primary">
              <v-toolbar flat>
                <v-icon size="x-large" class="pb-1 pl-2">{{subComponentData.icon}}</v-icon>
                <v-toolbar-title>{{subComponentData.name}}</v-toolbar-title>
              </v-toolbar>
              <!-- COMPONENT VIEW -->
              <router-view v-if="getLoading.status == false"/>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <appNotification></appNotification>
    <loading></loading>
  </v-app>
</template>

<script>
// LIBRARIES
import { mapStores }    from 'pinia'

// STORES
import { defaultStore } from '@/stores/defaultStore'
import { navStore }     from '@/stores/navStore'

// COMPONENTS
import appFooter        from './components/appComponents/Footer.vue'
import appHeader        from './components/appComponents/Header.vue'
import appNav           from './components/appComponents/Nav.vue'
import appNotification  from './components/appComponents/Notification.vue'
import loading          from './components/appComponents/Loading.vue'

export default {
  components: {
    appFooter, appHeader, appNav, appNotification, loading
  },
  created(){
    this.defaultStore.fetchUsers()
    this.defaultStore.fetchGroups()
    this.defaultStore.fetchMatches()
    this.defaultStore.fetchParticipants()
    this.defaultStore.fetchSettings()
    this.defaultStore.fetchTeams()
  },
  mounted(){
    this.defaultStore.loadingStatus({msg: "", status: false})
  },
  data(){
    return {
      showNav     : true,
      selected    : "/",
    }
  },
  methods: {
    goToSub(item){
      // LOGIC FOR GENERAL PAGES
      if (item.parent == undefined){
        if (item == "/" || item == "/Matches" || item == "/Login"){
          this.selected    = item
          this.$router.push(item)
        }
        else if(item == "/Logout"){
          this.selected = "/"
          this.defaultStore.logout()
        }
        else if(item == "/Rules") {
          let rulesLink = this.defaultStore.settings[0].rules
          if (rulesLink.active == true){
            let win       = window.open(rulesLink.link, "_blank") 
            win.focus()
          }
          else {
            window.alert(rulesLink.altmsg)
          }
        }
        else {
          this.selected = item
          this.$router.push(item)
        }
      }
      // FOR THE ADMIN PAGES
      else {
          let address   = item.parent + item.link
          this.selected = item.parent
          this.$router.push({path: address})
      }
    }
  },
  computed: {
    ...mapStores(defaultStore, navStore), 
    getLoading(){
      return this.defaultStore.loading
    },
    subComponentData(){
      var subData   = {
        name  : this.navStore.getCombinedNav.filter(item => { if (item.link == this.selected){ return item.name }})[0].name,
        icon  : this.navStore.getCombinedNav.filter(item => { if (item.link == this.selected){ return item.icon }})[0].icon,
        items : this.navStore.subNav.filter(item => item.parent == this.selected)
      } 
      return subData
    },
    userData(){
      this.defaultStore.getUserLocalState
      .then(res => {
        this.defaultStore.user = res
        return res
      })
      .catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
/* .fillview{
  min-height: calc(100% - 64px);
} */
.scroll-container {
  overflow: auto;
  height: auto;
}
</style>