import axios from 'axios'

export default {
    sendMatchUpdate(matchInfo, settings){
      let embedContent = {
        "embeds": [
          {
            "title": `WLDL MATCH UPDATE`,
            "color": "444444",
            "thumbnail": {
              "url": settings[0].logo
            },
            "fields": [
              {
                "name": "Teams:",
                "value": `**${matchInfo.teamA}** VS **${matchInfo.teamB}**`,
                "inline": false
              },
              {
                "name": "Date/Time",
                "value": matchInfo.date == "" ? "TBD" : matchInfo.date + ", " + matchInfo.time,
                "inline": true
              },
              {
                "name"  : "Score",
                "value" : getScore(),
                "inline": true
              },
              {
                "name"  : "Link",
                "value" : `http://wldleague.us/Matches/Match/${matchInfo.id}`,
                "inline": false
              }
            ]
          }
        ],
      }
      function getScore(){
        if (matchInfo.games.length == 0){
            return "N/A"
        }
        else{
            let a = 0
            let b = 0
            matchInfo.games.forEach((game) => {
                game.winner == matchInfo.teamA ? a++ : b++
            })
            return `${a} - ${b}`
        }
      }
      var config = {
          method: "POST",
          url: "https://discord.com/api/webhooks/1051187766794395749/SdlE1C4ST_lF4us0_s4RvicG8PyovSa9sIIZEkTmuS3LqIp-S5dKXfmhKW1q1Hcd4GIl",            
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify(embedContent)
      };
      axios(config)
        .then((response) => {
            console.log("Webhook delivered successfully");
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
    },
    sendPlayerReg(playerInfo){
        let embedContent = {
            "embeds": [
              {
                "title": "WLDL PLAYER REGISTRATION",
                "color": "123123",
                "thumbnail": {
                    "url": playerInfo.profile.avatarmedium != "" && playerInfo.profile.avatarmedium != null ? playerInfo.profile.avatarmedium : "https://i.imgur.com/r5R2OeB.png"
                },
                "fields": [
                  {
                    "name": "Steam Name:",
                    "value": playerInfo.steam,
                    "inline": true
                  },
                  {
                    "name": "Discord Name:",
                    "value": playerInfo.discord,
                    "inline": true
                  },
                  {
                    "name": "Dotabuff:",
                    "value": playerInfo.dotabuff,
                    "inline": false
                  },
                  {
                    "name": "Team:",
                    "value": playerInfo.team == "" ? "Free Agent" : playerInfo.team,
                    "inline": false
                  }
                ]
              }
            ],
        }
        var config = {
            method: "POST",
            url: "https://discord.com/api/webhooks/1051187766794395749/SdlE1C4ST_lF4us0_s4RvicG8PyovSa9sIIZEkTmuS3LqIp-S5dKXfmhKW1q1Hcd4GIl",
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify(embedContent)
         };
         axios(config)
            .then((response) => {
                console.log("Webhook delivered successfully");
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    sendTeamReg(team, players, settings){
        function setEmbed(){
            let embedContent = {
                "embeds": [
                  {
                    "title": `A TEAM HAS REGISTERED FOR ${settings[0].title}`,
                    "color": "424242",
                    "image": {
                        "url": team.logo != "" || team.logo != null ? team.logo : "https://i.imgur.com/r5R2OeB.png"
                    },
                    "fields": [
                        {
                            "name": "Team Name:",
                            "value": team.name,
                            "inline": false
                        },
                        {
                            "name": "Team Captain:",
                            "value": team.captain,
                            "inline": false
                        },
                        {
                            "name"  : "Player 1:",
                            "value" : players[0].steam,
                            "inline": true
                        },
                        {
                            "name"  : "Player 2:",
                            "value" : players[1].steam,
                            "inline": true
                        },
                        {
                            "name"  : "Player 3:",
                            "value" : players[2].steam,
                            "inline": true
                        },
                        {
                            "name"  : "Player 4:",
                            "value" : players[3].steam,
                            "inline": true
                        },
                        {
                            "name"  : "Player 5:",
                            "value" : players[4].steam,
                            "inline": true
                        },
                        {
                            "name"  : "Player 6:",
                            "value" : players[5] != undefined ? players[5].steam : "N/A",
                            "inline": true
                        },
                        {
                            "name"  : "Player 7:",
                            "value" : players[6] != undefined ? players[6].steam : "N/A",
                            "inline": true
                        },
                    ]
                  }
                ],
            }
            sendEmbed(embedContent)
        }
        
        function sendEmbed(embedContent){
            var config = {
                 method: "POST",
                 url: "https://discord.com/api/webhooks/1051187766794395749/SdlE1C4ST_lF4us0_s4RvicG8PyovSa9sIIZEkTmuS3LqIp-S5dKXfmhKW1q1Hcd4GIl",
                 headers: { "Content-Type": "application/json" },
                 data: JSON.stringify(embedContent)
            };
            axios(config)
            .then((response) => {
                // console.log("Webhook delivered successfully");
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
        }
        setEmbed()
    },
}