<template>
  <v-list bg-color="white" color="secondary" nav density="compact">
    <v-list-subheader>MENU</v-list-subheader>
    <v-divider></v-divider>
    <v-list-item v-for="item in userNav" :key="item.name" @click="$emit('goToSub', item.link)" style="cursor: pointer">
      <template v-slot:prepend>
        <v-icon :icon="item.icon"></v-icon>
      </template>
      <v-list-item-title v-text="item.name" class="text-uppercase"></v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-subheader>ADMIN</v-list-subheader>
    <v-divider></v-divider>
    <template v-if="userData != null">
      <template v-for="item in adminNav" :key="item.name">
        <v-list-item v-if="item.access <= userData.role.slice(0,1)" @click="$emit('goToSub', item.link)" style="cursor: pointer">
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
            <!-- <v-icon start class="mr-2">{{item.icon}}</v-icon> -->
          <v-list-item-title v-text="item.name" class="text-uppercase"></v-list-item-title>
        </v-list-item>
      </template>
    </template>
    <v-divider></v-divider>
    <v-list-item @click="$emit('goToSub', userState ? '/Login' : '/Logout')" class="mb-5" style="cursor: pointer">
      <template v-slot:prepend>
        <v-icon>{{ userState ? 'mdi-login-variant' : 'mdi-logout-variant'}}</v-icon>
      </template>
      <v-list-item-title class="text-uppercase">{{ userState ? 'Login' : 'Logout'}}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<script setup>
// VUE METHODS
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

// COMPONENTS

// STORES
import { defaultStore } from '@/stores/defaultStore'
import { navStore }     from '@/stores/navStore'
const store     = defaultStore()
const fullNav   = navStore()
const router    = useRouter()

// DATA
const props     = defineProps(['showNav']) 
const showNav   = ref(true)

//METHODS

//COMPUTED
const adminNav  = fullNav.adminNav
const userData  = computed(() => {
  return store.user
})
const userNav   = fullNav.baseNav
const userState = computed(() => {
  if (store.user == null || store.user == undefined){
    return true
  }
  else {
    return false
  }
})

</script>
<style scoped>

</style>