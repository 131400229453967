<template>
    <v-app-bar flat color="primary" class="justify-center">
      <v-app-bar-nav-icon @click="$emit('showNav')"></v-app-bar-nav-icon>
      <v-app-bar-title>{{siteTitle}}</v-app-bar-title>
      <template v-slot:append>
          <v-btn size="large" @click="changeUserState()">
            <v-icon start>{{ userData == null ? "mdi-login-variant" : "mdi-logout-variant"}}</v-icon>
            {{ userData == null ? "Login" : "Logout"}}
          </v-btn>
      </template>
    </v-app-bar>
</template>

<script setup>

// VUE METHODS
import { computed }   from 'vue'
import { useRouter }  from 'vue-router'
import { defaultStore } from '@/stores/defaultStore'


// const props   = defineProps(['siteTitle'])
const router  = useRouter()
const store   = defaultStore()

//METHODS
const changeUserState = function(){
    if (userData.value == null){
      router.push("/Login")
    }
    else {
      store.logout()
    }
}

//COMPUTED
const siteTitle = computed(() => {
  if (store.settings[0] == undefined) {
    return ""
  }
  else {
    document.title = store.settings[0].title
    return store.settings[0].title
  }
})
const userData  = computed(() => store.user)

</script>