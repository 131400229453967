// LIBRARIES/METHODS
import { createApp }        from 'vue'
import { createPinia }      from 'pinia'
import { SetupCalendar }    from 'v-calendar'
import 'v-calendar/dist/style.css'

// FILES
import App              from './App.vue'
import router           from './router/index.js'
import vuetify          from "./plugins/vuetify"
import { loadFonts }    from './plugins/webfontloader'
loadFonts()

//CALENDAR


//VUE CONFIG
const app   = createApp(App)
app.use(createPinia())
app.use(router)
app.use(vuetify)
app.use(SetupCalendar, {})
app.mount('#app')