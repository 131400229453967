import axios                from 'axios'
import router               from '../router/index'
import { initializeApp }    from 'firebase/app'
import { getStorage, getDownloadURL, ref, uploadBytes }                                                         from "firebase/storage";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth"
import { getFirestore, addDoc, collection, doc, updateDoc, deleteDoc, onSnapshot }                              from 'firebase/firestore'

import discordUpdates       from './discordUpdates.js'

const firebaseConfig = {
    apiKey          : "AIzaSyCjixdpUd6WC_xnicWpTLcFuBTah7NP4Kg",
    authDomain      : "wldl-488e9.firebaseapp.com",
    databaseURL     : "https://wldl-488e9.firebaseio.com",
    projectId       : "wldl-488e9",
    storageBucket   : "wldl-488e9.appspot.com",
    appId           : "1:493596788815:web:dcd797002c5490d1"
};

const ODKey     = "4d1a0b95-1e27-44dd-a8bc-0cc4c56eb4dd"
const openDota  = "https://api.opendota.com/api/players"

const app           = initializeApp(firebaseConfig)
const auth          = getAuth(app)
const db            = getFirestore(app)
const storage       = getStorage(app)

export default{
    addGroup(group){
        addDoc(collection(db, "Groups"), group)
        this.setDialog({msg: `Group has been added.`, status: true})
        this.logAction("Added Group", group)
    },
    addMatch(match){
        addDoc(collection(db, "Matches"), match)
        this.setDialog({msg: `Match has been added.`, status: true})
        // discordUpdates.sendMatchUpdate(match)
        this.logAction("Added Match", match)
    },
    addPlayer(player){
        this.getODLink(player)
        .then(playerData => {
            addDoc(collection(db, "Players"), playerData)
            discordUpdates.sendPlayerReg(player)
            this.setDialog({msg: `${player.steam} has been registered.`, status: true})
        })
        .catch(err => {
            console.log(err)
        })
    },
    addStage(stage){
        addDoc(collection(db, "Stages"), stage)
        this.setDialog({msg: `Tournament stage has been added.`, status: true})
        this.logAction("Added Stage", stage)
    },
    addTeam(team){
        let teamInfo = {
            captain : team.roster[0].steam,
            group   : team.group,
            logo    : team.logo,
            name    : team.name,
        }
        addDoc(collection(db, "Teams"), teamInfo)
        let playerArray = []
        team.roster.forEach(player => {
            player.team = team.name
            if (player.steam != ""){
                playerArray.push(
                    this.getODLink(player)
                    .then(res => {
                        return res
                    })
                    .catch(err => {
                        console.log(err)
                        return err
                    })
                )
            }
        })
        Promise.all(playerArray)
        .then(res => {
            let teamPlayers = []
            res.forEach(item => {
                // console.log(item)
                teamPlayers.push(item)
                addDoc(collection(db, "Players"), item)

            })
            this.setDialog({msg: `${team.name} and all associated players have been registered.`, status: true})
            return teamPlayers
        })
        .then(teamPlayers => {
            discordUpdates.sendTeamReg(teamInfo, teamPlayers, this.settings)
        })
        .catch(err => {
            console.log(err)
            this.setDialog({msg: `There was an error. Please check the console.`, status: true})
        })
    },
    addOnlyTeamInfo(team){
        addDoc(collection(db, "Teams"), team)
        this.setDialog({msg: `${team.name} has been registered with an empty roster.`, status: true})
        this.logAction('Added team', team)
    },
    addUser(user){
        let tempPass = Math.random().toString().slice(2, 10)
        createUserWithEmailAndPassword(auth, user.email, tempPass)
        .then(() => {
            addDoc(collection(db, "Users"), user)
            this.setDialog({msg: `${user.handle} has been created.`, status: true})
            this.userPasswordReset(user)
            this.logAction('Added user', user)
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(error)
            this.setDialog({msg:`There was an error. Please check the console.`, status: true})
        });
    },
    async deleteGroup(group){
        await deleteDoc(doc(db, "Groups", group.id))
        this.setDialog({msg: `${group.name} has been deleted.`, status: true})
        this.logAction('Deleted group', group)
    },
    async deleteMatch(match){
        await deleteDoc(doc(db, "Matches", match.id))
        this.setDialog({msg:`Match has been deleted.`, status: true})
        this.logAction('Deleted match', match)
    },
    async deletePlayer(player){
        await deleteDoc(doc(db, "Players", player.id))
        this.setDialog({msg:`Player has been deleted.`, status: true})
        this.logAction('Deleted player', player)
    },
    async deleteTeam(team){
        let teamPlayers = this.players.filter(player => player.team == team.name)
        // DELETE THE TEAM FROM EVERY ROSTER MEMBER
        teamPlayers.forEach(player => {
            player.team = ""
            let playerUpdate = doc(db, "Players", player.id)
            updateDoc(playerUpdate, player)
        })
        await deleteDoc(doc(db, "Teams", team.id))
        this.setDialog({msg:`Team has been deleted.`, status: true})
        this.logAction('Deleted team', team)
    },
    async deleteUsers(userList){
        await userList.forEach(usr => {
            deleteDoc(doc(db, "Users", usr.id))
            this.setDialog({msg:`User has been deleted.`, status: true})
            this.logAction('Deleted user', usr)
        })
    },
    async editGroup(group){
        let groupUpdate = doc(db, "Groups", group.id)
        await updateDoc(groupUpdate, group)
        this.setDialog({msg:`Group has been updated.`, status: true})
        this.logAction('Edited group', group)
    },
    async editMatch(match){
        let matchUpdate = doc(db, "Matches", match.id)
        await updateDoc(matchUpdate, match)
        discordUpdates.sendMatchUpdate(match, this.settings)
        this.setDialog({msg:`Match has been updated.`, status: true})
        this.logAction('Edited match', match)
    },
    async editPlayer(player){
        let playerUpdate = doc(db, "Players", player.id)
        await updateDoc(playerUpdate, player)
        this.setDialog({msg:`Player has been updated.`, status: true})
        this.logAction('Edited player', player)
    },
    async editSettings(settings){
        let settingsUpdate = doc(db, "Settings", settings.id)
        await updateDoc(settingsUpdate, settings)
        this.setDialog({msg:`Settings have been updated.`, status: true})
        this.logAction('Edited settings', settings)
    },
    async editStage(stage){
        let stageUpdate = doc(db, "Stages", stage.id)
        await updateDoc(stageUpdate, stage)
        this.setDialog({msg:`Tournament stage has been updated.`, status: true})
        this.logAction('Edited stage', stage)
    },
    async editTeam(team){
        let teamUpdate = doc(db, "Teams", team.id)
        await updateDoc(teamUpdate, team)
        this.setDialog({msg:`Team has been updated.`, status: true})
        this.logAction('Edited team', team)
    },
    async editUser(user){
        let userUpdate = doc(db, "Users", user.id)
        await updateDoc(userUpdate, user)
        this.setDialog({msg:`User has been updated.`, status: true})
        this.logAction('Edited user', user)
    },
    fetchGroups(){
        const unsub = onSnapshot(collection(db, "Groups"), (allGroups) => {
            let groupData = []
            allGroups.forEach(group => {
                let info = {
                    ...group.data()
                }
                info.id = group.id
                groupData.push(info)
            })
            this.groups = groupData
        })
    },
    async fetchLogs(){
        const unsub = onSnapshot(collection(db, "Logs"), (allLogs) => {
            let logData = []
            allLogs.forEach(log => {
                let info = {
                    ...log.data()
                }
                info.id = log.id
                logData.push(info)
            })
            this.logs = logData
        })
        return 
    },
    fetchMatches(){
        const unsub = onSnapshot(collection(db, "Matches"), (allMatches) => {
            let matchData = []
            allMatches.forEach(match => {
                let info = {
                    ...match.data()
                }
                info.id = match.id
                matchData.push(info)
            })
            this.matches = matchData
        })
    },
    fetchParticipants(){
        const unsub = onSnapshot(collection(db, "Players"), (allPlayers) => {
            let playerData = []
            allPlayers.forEach(player => {
                let info = {
                    ...player.data()
                }
                info.id = player.id
                playerData.push(info)
            })
            this.players = playerData
        })
    },
    fetchSettings(){
        const unsub = onSnapshot(collection(db, "Settings"), (allSettings) => {
            let settingData = []
            allSettings.forEach(setting => {
                let info = {
                    ...setting.data()
                }
                info.id = setting.id
                settingData.push(info)
            })
            this.settings = settingData
        })
    },
    fetchTeams(){
        const unsub = onSnapshot(collection(db, "Teams"), (allTeams) => {
            let teamData = []
            allTeams.forEach(team => {
                let info = {
                    ...team.data()
                }
                info.id = team.id,
                teamData.push(info)
            })
            this.teams = teamData
        })
    },
    fetchUsers(){
        const unsub = onSnapshot(collection(db, "Users"), (allUsers) => {
            let userData = []
            allUsers.forEach(user => {
                let info = {
                    ...user.data()
                }
                info.id = user.id
                userData.push(info)
            })
            this.allUsers = userData
        })
    },
    async getODLink(player){
        return axios.get(`${openDota}/${player.steamId}?api_key=${ODKey}`)
        .then(res => {
            player.profile = res.data.profile
            return player
        })
        .catch(err => {
            return err
        })
    },
    async loadingStatus(status){
        if (status.status == true){
            this.loading = {msg: status.msg, status: status.status}
        }
        else {
            setTimeout(() => {
                this.loading = {msg: status.msg, status: status.status}
            }, 1500);
        }
    },
    logAction(action, data){
        let logData = {
            action  : action,
            data    : data,
            date    : new Date(),
            user    : this.user.email == undefined ? "Anonymous" : this.user.email
        }
        addDoc(collection(db, "Logs"), logData)

    },
    async login(user){
        await signInWithEmailAndPassword(auth, user.name, user.pass)
        .then((userCredential) => {
            let activeUser      = userCredential.user
            let userData        = this.allUsers.find(usr => usr.email.toLowerCase() == activeUser.email.toLowerCase())
            activeUser.handle   = userData.handle
            activeUser.id       = userData.id
            activeUser.role     = userData.role
            this.user           = activeUser
            localStorage.setItem("userData", JSON.stringify(activeUser))
            router.push("/")
            this.setDialog({msg: `${this.user.email} has been logged in.`, status: true})
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage)
            this.setDialog({msg: errorMessage, status: true})
        });
    },
    logout(){
        signOut(auth).then(() => {
           this.user = null
           localStorage.removeItem("userData")
           router.push("/")
        }).catch((error) => {
            console.log(error)
        });
    }, 
    resetLeague(){
        this.groups.forEach(group => {
            this.deleteGroup(group)
        })
        this.players.forEach(player => {
            this.deletePlayer(player)
        })
        this.teams.forEach(team => {
            this.deleteTeam(team)
        })
        this.matches.forEach(match => {
            this.deleteMatch(match)
        })
        this.setDialog({msg:`League has been reset.`, status: true})
        this.logAction('Reset League', {})
    },
    setDialog(status){
        this.dialog = {
            msg     : status.msg,
            status  : status.status
        }
    },
    async uploadFile(file){
        var fileRef = ref(storage, file.name)
        return uploadBytes(fileRef, file).then((snapshot) => {
            return getDownloadURL(ref(fileRef))
        })
        .catch(err => {
            return err
        })
    },
    userPasswordReset(user){
        sendPasswordResetEmail(auth, user.email)
        .then(() => {
            if (user.id !== ""){
                this.setDialog({msg:`Password reset has been sent to ${user.handle}`, status: true})
                this.logAction('Sent password reset', user)
            }
        })
        .catch((error) => {
            console.log(error)
            this.setDialog({msg: 'There was an error. Please check the console.', status: true})
        });
    }
}