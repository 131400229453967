<template>
    <v-dialog v-model="loading.status" width="300" height="300">
        <v-card 
            height="300" width="300"
            color="primary"
            class="d-flex justify-center align-center pa-10 text-uppercase text-center"
        >
            <v-progress-linear indeterminate class="mb-3"></v-progress-linear>
            {{loading.msg}}   
        </v-card>
    </v-dialog>
</template>
<script setup>
import { defaultStore } from '@/stores/defaultStore'
import { computed } from 'vue'
const store     = defaultStore()
const loading   = computed(() => store.loading)

</script>